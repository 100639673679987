import Image from "next/image"
import { Button, Heading, Wysiwyg } from "@/ui"

export const config = {
	id: "mre_templates:filiale_details",
}

const ReclamationAssistanceWidget = ({ data }) => {
	const title = data?.components?.[0]?.title
	const image = data?.components?.[0]?.image?.[0]
	const cardContent = data?.components?.[0]?.card_content?.value?.["#text"]
	const mainContent = data?.components?.[0]?.main_content?.value?.["#text"]
	const link = data?.components?.[0]?.link
	const link_2 = data?.components?.[0]?.link_2

	return (
		<div className="mx-5 lg:mx-auto lg:max-w-5xl border border-primary my-5 md:my-10 bg-white">
			<div className="py-[30px] mx-auto max-w-5xl px-5 md:px-[30px]">
				<div className="flex flex-col gap-8 md:flex-row md:items-center">
					<div className="md:w-1/2">
						<div className="relative h-20 w-[240px] mx-auto overflow-hidden">
							<Image
								src={image?._default}
								alt={image?.meta?.alt || "filiale details image"}
								layout="fill"
								className="w-full h-full object-contain"
							/>
						</div>
					</div>
					<div className="md:w-1/2 flex flex-col">
						<Heading variant="6" level="6" className="mb-2 leading-7">
							{title}
						</Heading>
						<Wysiwyg html={cardContent} />
						<Button
							variant="mre"
							href={link.url}
							target={link.attributes?.target || null}
							className="mt-4"
						>
							{link.title}
						</Button>
					</div>
				</div>
				<Wysiwyg html={mainContent} className="mt-10 bg-[#F8F8F8] p-[30px]" />
				<Button
					variant="mre"
					href={link_2.url}
					target={link_2.attributes?.target || null}
					className="mt-4"
				>
					{link_2.title}
				</Button>
			</div>
		</div>
	)
}

export default ReclamationAssistanceWidget
