import { forwardRef } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
// import { Icon } from "../icon/Icon"
import { Link } from "../link/Link"
import { button } from "./theme"
import { Icon } from "@/ui"

const CustomButton = ({ children, icon, ...props }) => {
	return (
		<>
			{props.href ? (
				<Link {...props}>
					{icon && icon}
					{children}
				</Link>
			) : (
				<button {...props}>
					{icon && icon}
					{children}
				</button>
			)}
		</>
	)
}

const Button = forwardRef(
	(
		{
			children,
			type = "button",
			className = "",
			variant = "primary",
			size = "normal",
			pill,
			outline = false,
			disabled = false,
			onClick,
			icon,
			isPlaying,
			href = null,
			target = null,
			color = "black",
			...props
		},
		ref
	) => {
		const finalClass = () => {
			if (variant === "mre") {
				return clsx(
					"inline-flex items-center justify-center w-full md:w-fit",
					"px-6 pt-4 pb-3",
					"ltr:border-b-2 ltr:border-r-2 rtl:border-b-2 rtl:border-r-2 relative isolate group",
					{
						"border-secondary text-white hover:text-secondary": color === "black",
						"border-white text-black hover:text-white": color === "white",
					},
					disabled ? "opacity-70" : "",
					className
				)
			}

			if (
				[
					"secondary",
					"secondary-black",
					"secondary-mre",
					"gray-mre",
					"secondary-white",
					"outlined",
				].includes(variant)
			) {
				return clsx(button.variant[variant].base, className)
			}
			if (variant === "play-video") {
				return clsx(button.variant["play-video"], className)
			}
			if (variant === "transparent-rounded") {
				return clsx(
					"md:p-4 p-2 rounded-full  transition border-transparent bg-transparent shadow-none hover:shadow focus:outline-none"
				)
			}

			return clsx(
				button.base,
				button.size[size],
				!outline ? button.variant[variant] : button.outlineVariant[variant],
				pill && button.pill,
				disabled && button.disabled,
				icon && button.icon,
				className
			)
		}

		return (
			<CustomButton
				ref={ref}
				disabled={disabled}
				type={type}
				onClick={onClick}
				href={href}
				target={target}
				className={finalClass()}
				{...props}
			>
				{variant === "mre" && (
					<div className={button.variant[variant].base}>
						<div className={button.variant[variant].corner} />
						{/* corner */}
						<div className={button.variant[variant].border.top} />
						{/* border-top */}
						<div className={button.variant[variant].border.left} />
						{/* border-left */}
						<div
							style={{
								backgroundImage:
									"linear-gradient(135deg,transparent 0,transparent 9px,currentColor 10px,currentColor 100%)",
							}}
							className={clsx(button.variant[variant].bg, {
								"text-white": color === "white",
								"text-secondary": color === "black",
							})}
						/>
					</div>
				)}

				{variant === "secondary" && (
					<div
						className={clsx(
							"absolute inset-y-0 left-0 w-0 group-hover:w-full transition bg-gray500"
						)}
					></div>
				)}
				{variant === "secondary-white" && (
					<div className="absolute inset-y-0 right-0 w-full group-hover:w-0 transition bg-white"></div>
				)}
				{variant === "secondary-black" && (
					<div
						className={clsx(
							"absolute inset-y-0 left-0 w-0 group-hover:w-full transition bg-black"
						)}
					></div>
				)}
				{variant === "secondary-mre" && (
					<div className="absolute inset-y-0 left-0 w-0 group-hover:w-full transition bg-secondary"></div>
				)}

				{icon && icon}

				{[
					"mre",
					"secondary",
					"secondary-black",
					"secondary-white",
					"secondary-mre",
					"outlined",
				].includes(variant) ? (
					<span className={button.variant[variant].textWrapper}>{children}</span>
				) : (
					children
				)}
				{variant === "play-video" && (
					<>
						<span className="sr-only">play video</span>
						{isPlaying ? (
							<Icon id="pause" className="w-12 h-12" />
						) : (
							<Icon
								id="play-rounded"
								className="rtl-icon w-12 h-12 fill-current text-white"
							/>
						)}
					</>
				)}
			</CustomButton>
		)
	}
)

Button.propTypes = {
	children: PropTypes.node.isRequired,
	submit: PropTypes.oneOf(["submit", "button"]),
	/** demo description */
	className: PropTypes.string,
	pill: PropTypes.bool,
	outline: PropTypes.bool,
	disabled: PropTypes.bool,
}

export { Button }
