export const text = {
	base: "text-base leading-5",
	small: "text-xs mb-4",
	medium: "text-lg",
	large: "text-xl",
	tabs: "text-xl mb-6",
	intro: "text-base md:text-2xl text-center my-1",
	introduction: "text-[17px] leading-[26px] text-center my-2",
	quote: "text-lg",
	searchDescription: "text-sm",
}
