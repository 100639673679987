import { useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { useErrorMessage } from "../hooks/useErrorMessage"
import { useI18n as useTranslation } from "@/hooks"
import { toRegister } from "../utils/toRegister"
import { Checkbox } from "@/ui"
import { useCheckConditions } from "../hooks/useCheckConditions"

export const CheckboxField = ({ name, field }) => {
	const { label, validation, shouldDisplay, variant, states, helperText } = field
	const { t } = useTranslation()
	const { register, watch } = useFormContext()
	const values = watch({ nest: true })
	const errorMessage = useErrorMessage(name, label)
	const isVisible = useMemo(() => {
		return shouldDisplay ? shouldDisplay(values) : true
	}, [values, shouldDisplay])

	const local_state = useCheckConditions(states)

	if (!isVisible) return null

	if (local_state?.invisible) return null
	if (local_state?.visible === false) return null

	var local_validation

	if (local_state["required"] !== undefined) {
		local_validation = { ...validation, required: local_state.required }
	} else {
		local_validation = { ...validation }
	}

	return (
		<div>
			<Checkbox
				variant={variant || "mre"}
				label={t(`Nx:${label}`)}
				required={local_validation?.required}
				// label={t(`Nx:${label}`) + `${local_validation?.required ? " *" : ""}`}
				name={name}
				type="checkbox"
				id={name}
				checked={values[name]}
				{...register(name, toRegister(name, local_validation, values, t))}
				hasError={!!errorMessage}
				errorMessage={!!errorMessage && errorMessage}
				description={helperText}
				disabled={local_state.disabled}
			/>
		</div>
	)
}
