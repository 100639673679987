import { forwardRef } from "react"
import { Checkbox } from "../checkbox/Checkbox"
import { checkboxes } from "./theme"

export const Checkboxes = forwardRef(
	(
		{
			variant = "default",
			label,
			hasError,
			errorMessage,
			checkboxesData,
			description,
			...rest
		},
		ref
	) => {
		return (
			<div className={checkboxes[variant].wrapper}>
				<label className={checkboxes[variant].label}>{label}</label>
				<div className={checkboxes[variant].checkboxesContainer}>
					{checkboxesData.map((checkbox, index) => {
						return (
							<Checkbox
								key={index}
								ref={ref}
								{...rest}
								variant={variant}
								label={checkbox.label}
								value={checkbox.value}
							/>
						)
					})}
				</div>
				{hasError && <p className={checkboxes[variant].errorMessage}>{errorMessage}</p>}
				{description && <p>{description}</p>}
			</div>
		)
	}
)
