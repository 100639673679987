import { forwardRef } from "react"
import clsx from "clsx"
import { input } from "./theme"

export const Input = forwardRef(
	(
		{
			label,
			variant = "default",
			placeholder,
			addonAfter = null,
			addonBefore = null,
			prefix = null,
			sufix = null,
			type = "text",
			handleSufixClick = null, // this only used in password case and it maight be optimized
			handleInputChange,
			hasError,
			errorMessage,
			description,
			required,
			fullWidth = false,
			onKeyPress,
			...rest
		},
		ref
	) => {
		return (
			<div
				className={clsx(
					`w-full`,
					!fullWidth && input[variant].container,
					input[variant].margin ? input[variant].margin : "mb-4"
				)}
			>
				{type !== "hidden" && label && (
					<label className={clsx(input[variant].label)}>
						{label}
						{required && (
							<span
								className={clsx(
									input[variant].required
										? input[variant].required
										: "font-bold mx-1 text-red-500"
								)}
								title="This field is required"
							>
								*
							</span>
						)}
					</label>
				)}
				<div
					className={clsx(
						fullWidth ? "w-full" : input[variant].wrapper,
						hasError && input[variant].hasError
					)}
				>
					{addonBefore && (
						<div className={clsx("flex", input[variant].addonBefore)}>{addonBefore}</div>
					)}
					<span
						className={clsx(
							addonBefore && addonAfter
								? input[variant].inputWrapper.inside
								: addonAfter
								? input[variant].inputWrapper.left
								: addonBefore
								? input[variant].inputWrapper.right
								: input[variant].inputWrapper.full
						)}
					>
						{prefix && <div className={clsx(input[variant].prefix)}>{prefix}</div>}
						<input
							ref={ref}
							onKeyPress={onKeyPress}
							onChange={(e) => handleInputChange?.(e.target.value)}
							className={clsx(input[variant].input, hasError && input[variant].hasError)}
							type={type}
							placeholder={placeholder}
							{...rest}
						/>
						{sufix && (
							<div
								className={clsx(input[variant].sufix)}
								onClick={() => {
									handleSufixClick?.()
								}}
							>
								{sufix}
							</div>
						)}
					</span>
					{addonAfter && (
						<div className={clsx("flex", input[variant].addonAfter)}>{addonAfter}</div>
					)}
				</div>
				{errorMessage && hasError && (
					<p
						className={clsx(
							fullWidth
								? input[variant].errorMessage.replace("md:w-7/12", "w-full")
								: input[variant].errorMessage
						)}
					>
						{errorMessage}
					</p>
				)}
				{description && <p className={input[variant].description}>{description}</p>}
			</div>
		)
	}
)
