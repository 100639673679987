import { useMemo } from "react"
import { useI18n as useTranslation } from "@/hooks"
import { useFormContext, Controller } from "react-hook-form"
import { useErrorMessage } from "../hooks/useErrorMessage"
import { toRegister } from "../utils/toRegister"
import { SelectMre as Select } from "@/ui"
import { useCheckConditions } from "../hooks/useCheckConditions"

export const SelectField = ({ name, field, form }) => {
	const { label, validation, shouldDisplay, states, fullWidth } = field
	const { t } = useTranslation()
	const { watch, control } = useFormContext()
	const values = watch({ nest: true })
	const errorMessage = useErrorMessage(name, label)

	const isVisible = useMemo(() => {
		return shouldDisplay ? shouldDisplay(values) : true
	}, [values, shouldDisplay])

	const local_state = useCheckConditions(states)

	if (!isVisible) return null

	if (local_state?.invisible) return null
	if (local_state?.visible === false) return null

	var local_validation

	if (local_state["required"] !== undefined) {
		local_validation = { ...validation, required: local_state.required }
	} else {
		local_validation = { ...validation }
	}

	return (
		<Controller
			variant="default"
			control={control}
			name={name}
			rules={toRegister(t("Nx:" + label), local_validation, values, t)}
			render={({ field: { onChange, value } }) => {
				if (!value && field.options[0]?.value && form) {
					form.setValue(name, field.options[0]?.value)
				}
				return (
					<Select
						// label={t(`Nx:${label}`) + `${local_validation?.required ? " *" : ""}`}
						label={label && t(`Nx:${label}`)}
						fullWidth={fullWidth}
						isRequired={local_validation?.required}
						list={field.options.map((option) => ({
							value: option.value,
							content: option.label || option.value,
						}))}
						selected={value || field.options[0]?.value}
						setSelected={(option) => {
							onChange(option)
						}}
						hasError={!!errorMessage}
						disabled={local_state?.disabled}
						errorMessage={!!errorMessage && errorMessage}
					/>
				)
			}}
		/>
	)
}
