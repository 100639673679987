import React, { useEffect, useRef, useState } from "react"
import get from "lodash.get"
import { Form } from "./components"
import { Icon, Link, Recaptcha, Wysiwyg } from "@/ui"
import { useAccount } from "hooks/useAccount"
import cogoToast from "cogo-toast"
import { useI18n } from "hooks/useI18n"
import { drupal } from "@/lib"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import { useForm } from "react-hook-form"
import { dlPush } from "@/lib/gtm"

export const config = {
	id: "mre_templates:ouvrir-compte",
}

const normalizer = (data) => {
	const webform_id = get(data, "components.0.webform.id", null)
	const elements = get(data, "components.0.webform.elements", null)
	let style = get(data, "components.0.webform.style", {})
	let buttons = get(data, "components.0.webform.buttons", {})
	// const component = get(data, 'components.0.component', null);
	const title = get(data, "extra_field.title", null)
	const description = get(data, "extra_field.intro.value.#text", null)
	const link = get(data, "extra_field.link.url", null)
	const link_label = get(data, "extra_field.link.title", null)

	return {
		webform_id,
		elements,
		style,
		buttons,
		title,
		description,
		link,
		link_label,
	}
}

const WebformOuvertureCompteWidget = (props) => {
	const { data } = props
	let { webform_id, elements, style, buttons, title, description } = normalizer(data)
	// const { t } = useI18n()
	const { profile, isAuthenticated } = useAccount()
	const [utms, setUtms] = useState(() => {
		// Attempt to get UTM parameters from local storage first
		const savedUtms = typeof window !== "undefined" ? localStorage.getItem("utms") : null
		return savedUtms ? JSON.parse(savedUtms) : {}
	})
	const [showConfirm, setShowConfirm] = useState(false)
	const [responseData, setResponseData] = useState({})

	const formatSubmitData = (values) => {
		values["utm_medium"] = values["utm_medium"] || utms["utm_medium"] || ""
		values["utm_source"] = values["utm_source"] || utms["utm_source"] || ""
		values["utm_campaign"] = values["utm_campaign"] || utms["utm_campaign"] || ""
		return values
	}

	useEffect(() => {
		let urlQueries = window?.location?.search?.slice?.(1) || ""
		urlQueries = new URLSearchParams(urlQueries)

		const utmVals = {
			utm_medium: urlQueries.get("utm_medium") || utms.utm_medium || "",
			utm_source: urlQueries.get("utm_source") || utms.utm_source || "",
			utm_campaign: urlQueries.get("utm_campaign") || utms.utm_campaign || "",
		}

		setUtms(utmVals)

		// Store the UTM parameters in local storage for persistence
		if (typeof window !== "undefined") {
			localStorage.setItem("utms", JSON.stringify(utmVals))
		}
	}, [])

	function injectDefaultValues(elements) {
		Object.entries(elements).map(([name, field]) => {
			if (field.type === "webform_flexbox") injectDefaultValues(field.childs)
			if (name === "last_name_no_ar") {
				field.default_value = profile.user.last_name || profile.user.full_name
			} else if (name === "first_name_no_ar") {
				field.default_value = profile.user.first_name
			} else if (name === "email") {
				field.default_value = profile.user.email
			} else if (name === "country_code") {
				field.default_value = field.options.find((i) => i.label.includes("+212"))?.value
			} else if (name === "telephone") {
				field.value = profile.user.telephone
			}
		})
	}
	if (isAuthenticated) injectDefaultValues(elements)

	const submissionHandler = (response) => {
		console.log(response)
		if (response.status !== 200) {
			const errorMsg = response?.message || "An error occured. Please try again"
			const { hide } = cogoToast.error(errorMsg, {
				hideAfter: 20,
				onClick: () => {
					hide()
				},
			})
			return
		}

		const requestState = response?.data?.settings?.response_data || {}
		if (requestState.error || requestState.code) {
			const errorMsg = `${requestState.code}: ${requestState.error}`
			const { hide } = cogoToast.error(errorMsg, {
				hideAfter: 20,
				onClick: () => {
					hide()
				},
			})
		} else {
			// Datalayer
			dlPush("created-account", {
				status: requestState.status,
				utm_source: requestState.utm_source,
				utm_medium: requestState.utm_medium,
				utm_campaign: requestState.utm_campaign,
				userId: requestState.prospectSpaceId,
			})

			setResponseData({
				timeout: parseInt(requestState?.timer) || 120,
				status: requestState.status,
				prospectSpaceId: requestState.prospectSpaceId,
				phoneCode: requestState.phoneCode,
				phoneNumber: requestState.phoneNumber,
			})
			setShowConfirm(true)
		}
	}

	// elements?.container_02.childs.country_code.default_value = elements.container_02.childs.country_code.options.find(i => i.label.includes("(+212)"))

	return (
		<div>
			<div className="mt-10">
				{title && (
					<div className="flex items-center mb-10">
						<Icon
							id={"user-avatar"}
							className="w-10 h-10 ltr:mr-3 rtl:ml-3"
							width="40"
							height="40"
						/>
						<h2 className="text-lg font-bold leading-tight guide_niveau_1">{title}</h2>
					</div>
				)}
				{description && (
					<Wysiwyg html={description} className="text-sm mb-5 font-semibold" />
				)}
				{elements ? (
					<div className="container">
						<div className="shadow-lg p-6 md:py-12 md:px-16 bg-white relative z-1">
							{showConfirm ? (
								<FormConfirmationPage params={responseData} />
							) : (
								<Form
									webformId={webform_id}
									schema={elements}
									styles={style}
									buttons={buttons}
									handleSubmission={submissionHandler}
									handleSubmitRedirection={false}
									formatSubmitData={formatSubmitData}
								/>
							)}
						</div>
					</div>
				) : null}
			</div>
		</div>
	)
}

const FormConfirmationPage = (props) => {
	const confirmRef = useRef()
	const { params } = props
	const { t } = useI18n()
	const { phoneCode, phoneNumber, prospectSpaceId, timeout, status: _status } = params
	const [status, setStatus] = useState(_status)
	React.useEffect(() => {
		if (typeof scrollTo === "function") scrollTo(confirmRef)
	})
	const onSubmit = (values) => {
		const formData = new FormData()
		Object.keys(values).forEach((key) => formData.append(key, values[key]))

		let endpoint = "ouvrir-un-compte-bancaire-en-ligne/sms-resend"
		let queryString = new URLSearchParams(values).toString()

		if (queryString) {
			endpoint += "?" + queryString
		}

		drupal
			.fetch(endpoint, {
				//noProxy: true,
				method: "POST",
				body: formData,
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((res) => {
				const payload = res.data
				let cogoType = "info"
				if (payload?.message?.code === "ok") {
					cogoType = "success"
					setStatus(payload?.status || "initiated")
				} else if (payload?.memssage?.code === "nok") {
					cogoType = "error"
				}
				const { hide } = cogoToast[cogoType](res?.statusText, {
					hideAfter: 20,
					onClick: () => {
						hide()
					},
				})
			})
			.catch((err) => {
				console.log(err)
				const { hide } = cogoToast.error(err?.message?.text || err?.message, {
					hideAfter: 20,
					onClick: () => {
						hide()
					},
				})
			})
	}

	let cntryCode = phoneCode?.match?.(/\(\+\d+\)/)?.[0] || phoneCode || ""

	return (
		<div ref={confirmRef} className="max-w-2xl mx-auto text-center">
			<div className="flex justify-center mb-5">
				<Icon id={"chat-mobile"} className="text-primary h-20" />
			</div>
			{status === "initiated" && (
				<>
					<h3 className="text-2xl font-bold mb-5">
						{t("Vous recevrez un lien par SMS sur votre numéro")}
						<br />
						{` ${cntryCode} ${phoneNumber} `}
					</h3>
					<p className="mb-10">
						{t(
							"Cliquez sur le lien pour activer votre espace personnel et poursuivre l'ouverture de votre compte"
						)}
					</p>
					<hr className="max-w-md mx-auto my-3 border-true-200" />
					<SMSResendForm
						timeout={timeout}
						onSubmit={onSubmit}
						phoneCode={phoneCode}
						phoneNumber={phoneNumber}
						prospectSpaceId={prospectSpaceId}
					/>
				</>
			)}

			{status === "already_initiated" && (
				<>
					<h3 className="text-2xl font-bold mb-5">
						{t("Vous avez déjà initier la création de votre compte.")}
					</h3>
					<p className="mb-10">
						{t(
							"Prière de terminer votre inscription en cliquant " +
								"sur le lien reçu par SMS. Vous pouvez demander " +
								"le renvoi d'un nouveau SMS également."
						)}
					</p>
					<hr className="w-full max-w-sm mx-auto my-3 border-true-300" />
					<SMSResendForm
						timeout={timeout}
						onSubmit={onSubmit}
						phoneCode={phoneCode}
						phoneNumber={phoneNumber}
						prospectSpaceId={prospectSpaceId}
					/>
				</>
			)}

			{status === "already_created" && (
				<>
					<h3>{t("Le numéro de téléphone saisi existe déjà.")}</h3>
					<p>
						{t(
							"Vous avez déjà complété une demande d’ouverture " +
								"de compte L’bankalik. Connectez-vous sur " +
								"l’application L’bankalik avec votre identifiant " +
								"et votre mot de passe personnels pour suivre " +
								"votre demande et accéder à vos services."
						)}
					</p>
				</>
			)}
		</div>
	)
}

const SMSResendForm = (props) => {
	const { timeout: _timeout, onSubmit, phoneCode, phoneNumber, prospectSpaceId } = props
	const { activeLocale: language, t } = useI18n()
	dayjs.extend(duration)
	const recaptchaRef = useRef()
	const [btnDisabled, setBtnDisabled] = useState(true)
	const [timeout, setTimeout] = useState(_timeout || 0)
	const [notReceived, setNotReceived] = useState(false)
	const { register, handleSubmit, setValue, clearErrors, errors, watch } = useForm({
		defaultValues: {
			prospectSpaceId,
			phoneCode,
			phoneNumber,
			captcha_response: null,
		},
	})

	const captchaResponse = watch("captcha_response")

	useEffect(() => {
		const interval = setInterval(() => {
			// console.log('inteval tick', timeout-1);
			if (timeout > 0) setTimeout(timeout - 1)
			if (timeout <= 0 && interval) {
				setBtnDisabled(false)
				clearInterval(interval)
			}
		}, 1000)

		return () => clearInterval(interval)
	})

	const reset = () => {
		setNotReceived(false)
		setTimeout(_timeout)
		setBtnDisabled(true)
	}

	const handleSubmitForm = (values) => {
		if (!captchaResponse) {
			alert(t("please check this"))
			return
		}
		onSubmit(values)
		reset()
	}

	if (notReceived)
		return (
			<form onSubmit={handleSubmit(handleSubmitForm)}>
				<div className="bg-true-100 p-2 rounded-lg">
					{t("Si vous n'avez pas recu un sms vous pouvez demander un nouveau code")}
					{timeout > 0 && (
						<div className="font-mono">
							{/* {t("dans {{x}} secondes", {x: dayjs.duration(timeout * 1000).format("mm:ss")})} */}
							{t("dans")}{" "}
							<span className="text-primary">
								{dayjs.duration(timeout * 1000).format("mm:ss")}
							</span>{" "}
							{t("secondes")}
						</div>
					)}
				</div>

				<input
					type="hidden"
					ref={register("captcha_response", { required: t("please check this") })}
				/>
				<input type="hidden" ref={register("prospectSpaceId")} />
				<input type="hidden" ref={register("phoneCode")} />
				<input type="hidden" ref={register("phoneNumber")} />

				{errors?.captcha_response && (
					<span className="text-feedback-red">{t("please check this")}</span>
				)}

				<div className="flex justify-center my-3">
					<Recaptcha
						ref={recaptchaRef}
						sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY}
						hl={language}
						onChange={(val) => {
							setValue("captcha_response", val)
							clearErrors("captcha_response")
						}}
						onExpired={() => {
							setValue("captcha_response", null)
							setValue("g-recaptcha-response", null)
						}}
						onErrored={() => {
							setValue("captcha_response", null)
							setValue("g-recaptcha-response", null)
						}}
					/>
				</div>

				<button
					type="submit"
					disabled={btnDisabled}
					//onClick={reset}
					className={
						(btnDisabled ? "grayscale pointer-events-none " : "") +
						"text-center inline-flex justify-between bg-primary text-white " +
						"border-2 border-primary hover:text-primary hover:bg-transparent " +
						"px-4 pt-1.5 pb-2 font-bold text-sm leading-none rounded-full"
					}
				>
					{t("Envoyer un nouveau SMS")}
				</button>
			</form>
		)

	return (
		<div className="text-center flex flex-col items-center">
			<Link
				href="/"
				className={
					"text-center inline-flex justify-between bg-primary text-white " +
					"border-2 border-primary hover:text-primary hover:bg-transparent " +
					"px-4 pt-1.5 pb-2 font-bold text-sm leading-none rounded-full mt-5"
				}
			>
				{t("Retourner vers la page d'accueil")}
			</Link>
			<button
				className="text-xs text-primary underline hover:no-underline mt-5"
				onClick={() => setNotReceived(true)}
			>
				{t("Je n'ai pas recu un code")}
			</button>
		</div>
	)
}

export default WebformOuvertureCompteWidget
