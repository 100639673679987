import { useMemo } from "react"
import { useFormContext } from "react-hook-form"
import { useErrorMessage } from "../hooks/useErrorMessage"
import { useI18n as useTranslation } from "@/hooks"
import { toRegister } from "../utils/toRegister"
import { Checkboxes } from "@/ui"
import { useCheckConditions } from "../hooks/useCheckConditions"

export const CheckboxesField = ({ name, field }) => {
	const { label, validation, shouldDisplay, states } = field
	const { t } = useTranslation()
	const { register, watch, defaultValues } = useFormContext()
	const values = watch({ nest: true })
	const errorMessage = useErrorMessage(name, label)
	const isVisible = useMemo(() => {
		return shouldDisplay ? shouldDisplay(values) : true
	}, [values, shouldDisplay])

	const local_state = useCheckConditions(states)

	if (!isVisible) return null

	if (local_state?.invisible) return null
	if (local_state?.visible === false) return null

	var local_validation

	if (local_state["required"] !== undefined) {
		local_validation = { ...validation, required: local_state.required }
	} else {
		local_validation = { ...validation }
	}

	return (
		<div>
			<Checkboxes
				label={t(`Nx:${label}`) + `${local_validation?.required ? " *" : ""}`}
				checkboxesData={field.options.map((radio) => {
					return {
						value: radio.value,
						label: radio.label || radio.name,
						checked: defaultValues[name]?.includes(radio.value),
					}
				})}
				{...register(name, toRegister(t("Nx:" + label), local_validation, values, t))}
				hasError={!!errorMessage}
				errorMessage={!!errorMessage && errorMessage}
				disabled={local_state?.disabled}
			/>
		</div>
	)
}
