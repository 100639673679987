import { forwardRef } from "react"
import clsx from "clsx"
import { select } from "./theme"

export const SelectMre = forwardRef(
	(
		{
			isRequired,
			label = "label",
			list,
			selected,
			setSelected,
			hasError,
			errorMessage,
			className,
			variant = "mre",
			fullWidth = false,
		},
		ref
	) => {
		// const [value, setValue] = useState(selected)

		return (
			<div ref={ref} className={clsx(select[variant].wrapper, className)}>
				{label && (
					<label className={select[variant].label} htmlFor="nom">
						{label}
						{isRequired && (
							<span
								className="font-bold mx-1 text-red-500"
								title="This field is required"
							>
								*
							</span>
						)}
					</label>
				)}
				<div className={!fullWidth ? select[variant].inputWrapper : "w-full"}>
					<select
						className={clsx(select[variant].input, hasError && select[variant].hasError)}
						defaultValue={selected}
						onChange={(e) => setSelected(e.target.value)}
					>
						{list.map((item) => (
							<option key={item.value} value={item.value}>
								{item.content}
							</option>
						))}
					</select>
				</div>
				{hasError && (
					<p
						className={clsx(
							fullWidth
								? select[variant].error.replace("md:w-7/12", "w-full")
								: select[variant].error
						)}
					>
						{errorMessage}
					</p>
				)}
			</div>
		)
	}
)
