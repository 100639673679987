import { forwardRef, useEffect, useRef, useState } from "react"
// import { Icon } from "../icon/Icon"
import clsx from "clsx"
import { checkbox } from "./theme"
import { Wysiwyg } from "@/ui"

export const Checkbox = forwardRef(
	(
		{
			variant = "default",
			label,
			disabled = false,
			checked = false,
			hasError,
			required = false,
			errorMessage,
			description,
			...rest
		},
		ref
	) => {
		const [isChecked, setIsChecked] = useState(checked)
		// eslint-disable-next-line no-unused-vars
		const [isdisabled, setDisabled] = useState(disabled)

		const checkboxRef = useRef()

		const toggleCheckbox = () => {
			!disabled && setIsChecked((prev) => !prev)
			!disabled && checkboxRef.current.click()
		}

		useEffect(() => {
			//onChange?.(isChecked)
		}, [isChecked])

		useEffect(() => {
			setIsChecked(checked)
		}, [checked])

		return (
			<div className="relative mb-4">
				<input
					type="checkbox"
					className="absolute w-0 h-0 left-56 invisible"
					disabled={isdisabled}
					{...rest}
					ref={(e) => {
						ref?.(e)
						checkboxRef.current = e
					}}
				/>
				<div className={checkbox[variant].wrapper}>
					<div
						role="checkbox"
						aria-checked="false"
						className={clsx(
							checkbox[variant].input,
							isChecked && disabled
								? checkbox[variant].checked["disabled"]
								: isChecked && !disabled
								? checkbox[variant].checked["enabled"]
								: !isChecked && disabled
								? checkbox[variant].unchecked["disabled"]
								: checkbox[variant].unchecked["enabled"]
						)}
						onClick={toggleCheckbox}
					>
						{/* <Icon id="check" className={checkbox[variant].icon} /> */}
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="4"
							stroke="currentColor"
							className={checkbox[variant].icon}
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M4.5 12.75l6 6 9-13.5"
							/>
						</svg>
					</div>
					<label
						className={clsx(
							disabled
								? checkbox[variant].label["disabled"]
								: checkbox[variant].label["enabled"]
						)}
						onClick={toggleCheckbox}
					>
						{label}
						{required && (
							<span
								className="font-bold mx-1 text-red-500"
								title="This field is required"
							>
								*
							</span>
						)}
					</label>
				</div>
				{hasError && (
					<span className={checkbox[variant].errorMessage}>{errorMessage}</span>
				)}
				{description && <Wysiwyg className="text-xs pl-8" html={description} />}
			</div>
		)
	}
)
